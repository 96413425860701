/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import './bootstrap';
import '@babel/polyfill';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import React, { useEffect, useReducer, useState } from 'react';
import { render } from 'react-dom';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	NavLink,
	Redirect,
	Link
} from "react-router-dom";
import Login from './sites/Login';
import Home from './sites/Home';
import ResourceList from './sites/ResourceList';
import ResourceForm from './sites/ResourceForm';
import ResourceDetail from './sites/ResourceDetail';
import ReportDetail from './sites/ReportDetail';
import ResourceDelete from './sites/ResourceDelete';
import ResourceRemove from './sites/ResourceRemove';
import UserForm from './sites/UserForm';
import { getLabelPlural, getMessage, role_resources } from './lib/labels';
import Register from './sites/Register';
import useAxios from 'axios-hooks';
import Loading from './components/Loading';
import Logout from './sites/Logout';
import ForgotPassword from './sites/ForgotPassword';
import ResetPassword from './sites/ResetPassword';

// if (process.env.NODE_ENV === 'development' && module.hot) {
//     module.hot.accept();
// }

function ResourceMenu({ resources }) {
	return (
		<nav className="container-fluid bg-darker text-white py-2">
			<ul className="p-0 m-0 list-inline text-center">
				{
					resources.map(resource => {
						return (
							<li key={resource} className="list-inline-item">
								<NavLink
									exact to={"/resource/" + resource}
									className="text-white"
									activeClassName="route-active">{getLabelPlural(resource)}
								</NavLink>
							</li>
						);
					})
				}
			</ul>
		</nav>
	);
}

function ResourceLayout({ children, message_state, message_dispatch, is_super, user }) {

	if (user && user.loading) {
		return <Loading />;
	}

	if (user && user.data.user === null) {
		return <Redirect to="/login" />;
	}

	const handleMessageClose = (e) => {
		e.preventDefault();
		message_dispatch({ type: 'close' })
	};
	return (
		<>
			<header className="pt-4 position-relative no-print">
				<div className="logo-wrapper mx-auto mb-4"><img src="/img/logo.svg" /></div>
				{
					user && (
						<div className="login bg-darker text-white mt-2 mr-2 p-3">
							<span>Hallo {user.data.user.name}</span><br />
							<Link to="/logout">Logout</Link>
						</div>
					)
				}
				<div className="messages pl-2 pt-2">
					{
						message_state.messages.map((payload, key) => (
							<div key={key} className={"position-absolute toast " + (message_state.show && key === message_state.messages.length - 1 ? 'show' : 'd-none')} role="alert" aria-live="assertive" aria-atomic="true">
								<div className="toast-header">
									<div className={'message-color rounded mr-1 ' + (payload.status === 'ok' ? 'bg-info' : 'bg-warning')}></div>
									<strong className="mr-auto">{getMessage(payload.status)}</strong>
									{/* <small>system</small> */}
									<button onClick={handleMessageClose} type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="toast-body">
									{payload.msg.toString()}
								</div>
							</div>
						))
					}
				</div>
				<ResourceMenu resources={role_resources[user.data.user.role]} />
			</header>
			<main>
				{
					React.Children.map(children, child => {
						return React.cloneElement(child, {
							role: user.data.user.role,
						})
					})
				}
			</main>
			<footer className="bg-darker text-white container-fluid py-4 no-print">
				<span>Webapplikation Musikschulen | Pianohaus Schoekle</span><br />
				{
					/* VERSIONIERUNGS-SCHEMA */
					// MAJOR: Phase
					// MINOR: Feedback Runde, kleine Feature packete, mehrere Fixes
					// PATCH: Hotfixes
				}
				<span className="version-number">6.0.0</span>
				<a className="link-muted ml-2" href="https://www.peterblickenstorfer.com" target="_blank">by peterblickenstorfer.com</a>
			</footer>
		</>
	);
}

function App() {

	// messages reducer (handler)
	const init_state = {
		messages: [],
		show: false,
	};

	function messageReducer(state, action) {
		switch (action.type) {
			case 'push':
				return { messages: state.messages.concat(action.payload), show: true };
			case 'close':
				return { messages: state.messages, show: false };
			default:
				throw new Error("unsupported action type for messages: " + action.type);
		}
	}
	const [message_state, message_dispatch] = useReducer(messageReducer, init_state);
	console.log('messages', message_state);

	const [is_super, setIsSuper] = useState(false);

	// const [{ data: user_data, loading: user_loading, error: user_error }, user_refetch] = useAxios('/json/checkauth', { useCache: false });
	// console.log('user data', user_data, user_loading, user_error);

	const init_user = {
		data: {
			user: null,
		},
		loading: true,
		error: null,
	};

	function userReducer(state, action) {
		switch (action.type) {
			case 'checked-in':
				return {
					data: action.payload,
					loading: false,
					error: null,
				};
			case 'logged-out':
				return {...init_user, loading: false};
			case 'fetching':
				return {
					...state,
					loading: true,
				};
			case 'error':
				return {
					...state,
					error: action.payload,
				};
			default:
				throw new Error("unsupported action type for user: " + action.type);
		}
	}

	const [user_state, user_dispatch] = useReducer(userReducer, init_user);

	useEffect(() => {
		user_dispatch({type: 'fetching'});
		fetch('/json/checkauth')
			.then(response => response.json())
			.then(data => {
				user_dispatch({
					type: 'checked-in',
					payload: data,
				});
			}).catch(reason => {
				user_dispatch({
					type: 'error',
					payload: reason,
				});
			});
	}, []);

	console.log({user_state});

	if (user_state.loading) {
		return <Loading />
	}

	if (user_state.error) {
		return <p>Error</p>
	}

	function handleSuperToggle(e) {
		e.preventDefault();
		setIsSuper(!is_super);
	}

	return (
		<Router>
			<div id="js-router-wrapper">
				<Switch>
					<Route exact path="/login" render={props => {
						return <Login key={props.location.pathname} {...props} user={user_state} user_dispatch={user_dispatch} message_dispatch={message_dispatch} message_state={message_state} />
					}} />
					<Route exact path="/forgot-password" render={props => {
						return <ForgotPassword key={props.location.pathname} {...props} message_dispatch={message_dispatch} message_state={message_state} />
					}} />
					<Route exact path="/reset-password/:token" render={props => {
						return <ResetPassword key={props.location.pathname} {...props} message_dispatch={message_dispatch} message_state={message_state} />
					}} />
					<Route exact path="/register" render={props => {
						return <Register key={props.location.pathname} {...props} message_dispatch={message_dispatch} />
					}} />
					<Route exact path="/logout" render={props => {
						return <Logout key={props.location.pathname} {...props} user={user_state} user_dispatch={user_dispatch} message_dispatch={message_dispatch} />
					}} />
					<Route exact path="/resource/:resource/delete/:id/:db_id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceDelete {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/:resource/remove/:id/:db_id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceRemove {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/:resource/form/:id/:db_id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceForm {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/user/form/:db_id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<UserForm {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/user/delete/:db_id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceDelete {...props} explicit_resource='user' message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/user/form" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<UserForm {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />

					<Route exact path="/resource/:resource/form" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceForm {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/report/:id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ReportDetail is_super={is_super} {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/:resource/:id" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceDetail is_super={is_super} {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/resource/:resource" render={props => {
						return (
							<ResourceLayout key={props.location.pathname} user={user_state} is_super={is_super} message_state={message_state} message_dispatch={message_dispatch}>
								<ResourceList is_super={is_super} {...props} message_dispatch={message_dispatch} user_dispatch={user_dispatch} />
							</ResourceLayout>
						);
					}} />
					<Route exact path="/" render={props => {
						return <Home key={props.location.pathname} {...props} message_dispatch={message_dispatch} />
					}} />
				</Switch>
			</div>
		</Router>
	);
}

render(<App />, document.getElementById('app'));

