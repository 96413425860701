import React from 'react';
import { useParams, Link, Redirect, withRouter } from 'react-router-dom';
import useAxios from 'axios-hooks';
import Loading from '../components/Loading.js';
import { getTuningPeriod, getLabel, menschifyDate, menschifyDateShort, menschifyDateSwiss } from '../lib/labels.js';
import CHFString from '../components/CHFString.js';

function getValueOr(val) {
    return val ? val : "-";
}

function RoleGuard({ role, children }) {
    if (role !== 'client') {
        return <>{children}</>
    }
    return null;
}

function getPersonName(person) {
    if (!person || person === null) {
        return "-";
    }
    const { first_name, middle_name, last_name } = person;
    if (first_name && middle_name && last_name) {
        return [first_name, middle_name, last_name].join(" ");
    }
    if (first_name && last_name) {
        return [first_name, last_name].join(" ");
    }
    if (last_name) {
        return last_name;
    }
    return "-";
}

function ReportBody({
    report,
    reports,
    instrument,
    location,
    client,
    person_report,
    person_location,
    person_client,
    offer,
    history,
    role,
}) {
    if (!instrument || !location || !client) {
        return (
            <section>
                <h1>Fehlende Daten für diesen Bericht</h1>
                <p>Mögliche Gründe:</p>
                <ul>
                    <li>Ist das Datum des Berichts richtig erfasst?</li>
                    <li>Sind die Verknüpften Datensätze erfasst?</li>
                    <li>Haben die Verknüpften Datensätze valide Gültigkeitsperioden?</li>
                </ul>
                <ul>
                    <li>Instrument: {instrument ? "ok" : <Link to="/resource/instrument/form">Erfassen</Link>}</li>
                    <li>Standort: {location ? "ok" : <Link to="/resource/instrument/form">Erfassen</Link>}</li>
                    <li>Kunde: {client ? "ok" : <Link to="/resource/instrument/form">Erfassen</Link>}</li>
                </ul>
            </section>
        );
    }

    const handleReportSelect = value => {
        console.log('report handle', value);
        const redirect_description = {
            pathname: `/resource/report/${value}`,
            //search: "?" + new URLSearchParams(query_filters_map).toString()
        };
        history.push(redirect_description);
        //return <Redirect to={`/resource/report/${value}`} />;
    };
    //console.log('reports', reports, true && reports);
    reports.sort((a, b) => {
        if (a.date < b.date) {
            return 1;
        }
        if (a.date > b.date) {
            return -1;
        }
        return 0;
    });

    console.log(report);
    const last_report_w_pitch = reports.filter(rep => {
        console.log(rep);
        return rep.id !== report.id
            && rep.tuning_done !== null
            && rep.tuning_done
            && rep.tone_pitch_tuned
            && report.date > rep.date;
    }).sort((a, b) => {
        if (a.date < b.date) {
            return 1;
        }
        if (a.date > b.date) {
            return -1;
        }
        return 0;
    });
    const last_pitch = last_report_w_pitch.length > 0 ? menschifyDateSwiss(last_report_w_pitch[0].date) : '-';

    return (
        <div className="report-detail">
            <div className="d-flex justify-content-between">
                <div className="title-block mb-2">
                    <h1 className="print-small">{`${instrument.instrument_type} ${instrument.brand} ${instrument.model}, ${instrument.manufacturer_serial_number}`}<Link className="no-print super" to={`/resource/instrument/${instrument.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></h1>
                    <p>Datum: {menschifyDateSwiss(report.date)}&nbsp;-&nbsp;Status: {getLabel(report.status) ? getLabel(report.status) : '-'}</p>
                </div>
                <div className="pt-2 pr-2 mono-font no-print">
                    <select className="form-control" value={report.entity_uuid} onChange={e => handleReportSelect(e.target.value)}>
                        {/* <option key={report.id} value={report.entity_uuid}>{menschifyDateSwiss(report.date)}</option> */}
                        {
                            reports && reports.map(report => {
                                return <option key={report.id} value={report.entity_uuid}>{menschifyDateSwiss(report.date)}</option>;
                            })
                        }
                    </select>
                </div>
            </div>
            <section>
                <div className="row">
                    <div className="col-md print-col">
                        <p className="text-big font-weight-bold">
                            Kunde
                            <RoleGuard role={role}><Link className="no-print super" to={`/resource/client/${client.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></RoleGuard>
                        </p>
                        <dl className="row">
                            <dt className="col-sm-4">Name:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(client.name) + " Nr. " + getValueOr(client.number)}</dd>
                            <dt className="col-sm-4">Telefon:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(client.phone)}</dd>
                            <dt className="col-sm-4">Strasse:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(client.street)}</dd>
                            <dt className="col-sm-4">PLZ / Ort:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(client.zip) + " " + getValueOr(client.city)}</dd>
                        </dl>
                    </div>
                    <div className="col-md no-print">
                        <p className="text-big font-weight-bold">
                            Schulleiter
                            {person_client !== null && <RoleGuard role={role}><Link className="no-print super" to={`/resource/person/${person_client.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></RoleGuard>}
                        </p>
                        {
                            person_client === null
                                ? <p>Keine Person erfasst.</p>
                                : (
                                    <dl className="row">
                                        <dt className="col-sm-4">Name:</dt>
                                        <dd className="col-sm-8 ml-2 ml-sm-0">{getPersonName(person_client)}</dd>
                                        <dt className="col-sm-4">Telefon:</dt>
                                        <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(person_client.phone)}</dd>
                                        <dt className="col-sm-4">Mobile:</dt>
                                        <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(person_client.mobile)}</dd>
                                        <dt className="col-sm-4">E-Mail:</dt>
                                        <dd className="col-sm-8 ml-2 ml-sm-0">{person_client.email ? (<a href={"mailto:" + person_client.email}>{person_client.email}</a>) : '-'}</dd>
                                    </dl>
                                )
                        }
                    </div>
                    <div className="col-12 no-print" ><hr /></div>
                    <div className="col-md print-col">
                        <p className="text-big font-weight-bold">
                            Standort
                            <RoleGuard role={role}><Link className="no-print super" to={`/resource/location/${location.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></RoleGuard>
                        </p>
                        <dl className="row">
                            <dt className="col-sm-4">Name:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(location.name)}</dd>
                            <dt className="col-sm-4">Raum:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.room)}</dd>
                            <dt className="col-sm-4">Strasse:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(location.street)}</dd>
                            <dt className="col-sm-4">PLZ / Ort:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(location.zip) + " " + getValueOr(location.city)}</dd>
                        </dl>
                    </div>
                    <div className="col-md no-print">
                        <p className="text-big font-weight-bold">
                            Verantwortlich
                        </p>
                        {
                            person_location === null || person_location === undefined || person_location?.length === 0
                                ? <p>Keine Person erfasst.</p>
                                : (
                                    person_location.map(person => {
                                        return (
                                            <dl key={person.entity_uuid} className="row">
                                                <dt className="col-sm-4">Name:</dt>
                                                <dd className="col-sm-8 ml-2 ml-sm-0">{getPersonName(person)}<RoleGuard role={role}><Link className="no-print super" to={`/resource/person/${person.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></RoleGuard></dd>
                                                <dt className="col-sm-4">Telefon:</dt>
                                                <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(person.phone)}</dd>
                                                <dt className="col-sm-4">Mobile:</dt>
                                                <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(person.mobile)}</dd>
                                                <dt className="col-sm-4">E-Mail:</dt>
                                                <dd className="col-sm-8 ml-2 ml-sm-0">{person.email ? (<a href={"mailto:" + person.email}>{person.email}</a>) : '-'}</dd>
                                            </dl>
                                        );
                                    })
                                )
                        }
                    </div>
                </div>
            </section>
            <hr className="no-print" />
            <section>
                <div className="row">
                    <div className="col-md print-col">
                        <p className="text-big font-weight-bold">
                            Instrument
                            <RoleGuard role={role}><Link className="no-print super" to={`/resource/instrument/${instrument.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></RoleGuard>
                        </p>
                        <dl className="row">
                            <dt className="col-sm-4">Typ:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.instrument_type)}</dd>
                            <dt className="col-sm-4">Marke:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.brand)}</dd>
                            <dt className="col-sm-4">Modell:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.model)}</dd>
                            <dt className="col-sm-4">Ausführung:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.finish2)}</dd>
                            <dt className="col-sm-4">Seriennummer:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.manufacturer_serial_number)}</dd>
                        </dl>
                    </div>
                    <div className="col-md print-col">
                        <p className="text-big">&nbsp;</p>
                        <dl className="row">
                            <dt className="col-sm-4">Jahrgang:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.year_of_manufacture)}</dd>
                            <dt className="col-sm-4">DC-Einbau:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{instrument.dc_system_date_of_installation === null ? '-' : menschifyDateShort(instrument.dc_system_date_of_installation)}</dd>
                            <dt className="col-sm-4">Einsatzbereich:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(instrument.field_of_application)}</dd>
                        </dl>
                    </div>
                </div>
            </section>
            <hr className="no-print" />
            <section>
                <div className="row">
                    <div className="col-md print-col">
                        <p className="text-big font-weight-bold">
                            Bericht
                        </p>
                        <dl className="row">
                            <dt className="col-sm-4">Stimmer:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getPersonName(person_report)}</dd>
                            <dt className="col-sm-4">Stimmung ausgeführt:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{
                                (typeof report.tuning_done === 'undefined'
                                    || report.tuning_done === null)
                                    ? '-'
                                    : (report.tuning_done ? menschifyDateSwiss(report.date) : 'Nein')
                            }</dd>
                            <dt className="col-sm-4">Letzte Stimmung:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{last_pitch}</dd>
                            <dt className="col-sm-4">Stg.-Rythmus:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getTuningPeriod(report.tuning_period_in_months)}</dd>
                            <dt className="col-sm-4">Tonhöhe Vorgef.:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(report.tone_pitch_on_arrival)} Hz</dd>
                            <dt className="col-sm-4">Tonhöhe Gestimmt:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(report.tone_pitch_tuned)} Hz</dd>
                        </dl>
                    </div>
                    <div className="col-md print-col">
                        <p className="text-big">&nbsp;</p>
                        <dl className="row">
                            <dt className="col-sm-4">Temperatur:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(report.temperature_in_grad_celcius)} °C</dd>
                            <dt className="col-sm-4">Luftfeuchtigkeit:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(report.humidity_in_percent) + " %"}</dd>
                            <dt className="col-sm-4">DC-Service ausgeführt:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{report.dc_service_done === null ? '-' : (report.dc_service_done ? 'Ja' : 'Nein')}</dd>
                            <dt className="col-sm-4">Oberflächenzustand:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(report.condition_of_surface)}</dd>
                            <dt className="col-sm-4">Notiz:</dt>
                            <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(report.notes)}</dd>
                        </dl>
                    </div>
                </div>
            </section>
            <hr className="no-print" />
            <section className="">
                <p className="text-big font-weight-bold">Empfehlungen:</p>
                <p className="col-lg-10 col-xl-8">{getValueOr(report.recommendations)}</p>
            </section>
            <hr className="no-print" />
            <section>
                <p className="text-big font-weight-bold">
                    Checkliste
                </p>
                <div className="row">
                    <div className="col-3 col-md-2 font-weight-bold">Klangkörper:</div>
                    <div className="col-1">gut</div>
                    <div className="col-1 offset-1">ungenügend</div>
                    <div className="col-4 offset-2 col-md-6 offset-md-1">Bemerkungen:</div>
                </div>
                <ul className="p-0">
                    {
                        [
                            'soundboard',
                            'bridge',
                            'strings',
                        ].map(item => {
                            const note = report[`check_${item}_note`];
                            const value = report[`check_${item}_value`];
                            return (
                                <li key={item} className="row">
                                    <div className="col-3 col-md-2"><span className="pl-2 inline-ellipsis">{getLabel(item)}:</span></div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'good' ? "✗" : " "}]</div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'medium' ? "✗" : " "}]</div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'bad' ? "✗" : " "}]</div>
                                    <div className="col-4 offset-2 col-md-6 offset-md-1">{note}</div>
                                </li>
                            );
                        })
                    }
                </ul>
                <div className="row">
                    <div className="col-3 col-md-2 font-weight-bold">Spielwerk:</div>
                    <div className="col-1">gut</div>
                    <div className="col-1 offset-1">ungenügend</div>
                    <div className="col-4 offset-2 col-md-6 offset-md-1">Bemerkungen:</div>
                </div>
                <ul className="p-0">
                    {
                        [
                            'hammerhead',
                            'whippens',
                            'axes',
                            'damping',
                            'pedals',
                        ].map(item => {
                            const note = report[`check_${item}_note`];
                            const value = report[`check_${item}_value`];
                            return (
                                <li key={item} className="row">
                                    <div className="col-3 col-md-2"><span className="pl-2 inline-ellipsis">{getLabel(item)}:</span></div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'good' ? "✗" : " "}]</div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'medium' ? "✗" : " "}]</div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'bad' ? "✗" : " "}]</div>
                                    <div className="col-4 offset-2 col-md-6 offset-md-1">{note}</div>
                                </li>
                            );
                        })
                    }
                </ul>
                <div className="row">
                    <div className="col-3 col-md-2 font-weight-bold">Tasten:</div>
                    <div className="col-1">gut</div>
                    <div className="col-1 offset-1">ungenügend</div>
                    <div className="col-4 offset-2 col-md-6 offset-md-1">Bemerkungen:</div>
                </div>
                <ul className="p-0">
                    {
                        [
                            'trimmings',
                            'playweight',
                            'keycovering',
                        ].map(item => {
                            const note = report[`check_${item}_note`];
                            const value = report[`check_${item}_value`];
                            return (
                                <li key={item} className="row">
                                    <div className="col-3 col-md-2"><span className="pl-2 inline-ellipsis">{getLabel(item)}:</span></div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'good' ? "✗" : " "}]</div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'medium' ? "✗" : " "}]</div>
                                    <div className="col-1 mono-font no-wrap">[{value === 'bad' ? "✗" : " "}]</div>
                                    <div className="col-4 offset-2 col-md-6 offset-md-1">{note}</div>
                                </li>
                            );
                        })
                    }
                </ul>
            </section>
            {
                ['super', 'admin', 'employee'].includes(role) && (
                    <>
                        <hr className="no-print" />
                        <section className="row">
                            <p className="col-sm-2 font-weight-bold">Interne Notizen:</p>
                            <p className="col-sm-10">{getValueOr(report.notes_internal)}</p>
                        </section>
                    </>
                )
            }
            {
                offer && <>
                    <hr className="no-print" />
                    <section className="no-print">
                        <div className="row">
                            <div className="col">
                                <p className="text-big font-weight-bold">
                                    Offerte
                                    <RoleGuard role={role}><Link className="no-print super" to={`/resource/offer/${offer.entity_uuid}?valid_date=${report.date}`}>[↗]</Link></RoleGuard>
                                </p>
                                <dl className="row">
                                    <dt className="col-sm-4">Datum:</dt>
                                    <dd className="col-sm-8 ml-2 ml-sm-0">{offer.date ? menschifyDateSwiss(offer.date) : '-'}</dd>
                                    <dt className="col-sm-4">Nummer:</dt>
                                    <dd className="col-sm-8 ml-2 ml-sm-0">{getValueOr(offer.offer_number)}</dd>
                                    <dt className="col-sm-4">Status:</dt>
                                    <dd className="col-sm-8 ml-2 ml-sm-0">{getLabel(getValueOr(offer.status))}</dd>
                                </dl>
                            </div>
                            <div className="col">
                                <p className="text-big">&nbsp;</p>
                                <dl className="row">
                                    <dt className="col-sm-4">Betrag:</dt>
                                    <dd className="col-sm-8 ml-2 ml-sm-0"><CHFString value={offer.amount} prefix={true} /></dd>
                                    <dt className="col-sm-4">PDF:</dt>
                                    <dd className="col-sm-8 ml-2 ml-sm-0">{
                                        offer.pdf ? (<a target="_blank" href={offer.pdf.url}>herunterladen</a>) : '-'
                                    }</dd>
                                </dl>
                            </div>
                        </div>
                    </section>
                </>
            }
            <div className="printonly pdf-footer">
                <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                    <div className="pb-2"><img src="/img/logo-report-print.svg" /></div>
                    <address>
                        Sagistrasse 8A, 8910 Affoltern am Albis<br />
                        Telefon 044 720 53 97, Mail service@pianohaus-schoekle.ch
                    </address>
                </div>
            </div>
        </div>
    );
}

function ReportDetail({ history, role, user_dispatch }) {
    const { id } = useParams();
    const [{ data, loading, error }, refetch] = useAxios(`/json/report/${id}`, { useCache: false });

    if (loading) {
        return (
            <Loading />
        );
    }

    if (error) {
        if (error.response.status === 401) {
            user_dispatch({ type: 'logged-out' });
            return null;
        }
        return <React.Fragment><p>Error</p><pre>{JSON.stringify(error)}</pre></React.Fragment>
    }

    const { report, reports } = data.payload;
    const newest_report = reports.reduce((newest_report, next_report) => {
        if (newest_report.date < next_report.date) {
            return next_report;
        }
        return newest_report;
    }, report);

    return (
        <section>
            <header className="bg-grayl container-fluid py-3 d-flex mb-4 no-print">
                <div className="container">
                    <section className="flex-grow-1 w-50">
                        <h1 className="subtitle">{getLabel('report')}</h1>
                        <dl className="row mb-0">
                            <dt className="col-md-3">Letzte Änderung</dt>
                            <dd className="col-md-9 mono-font">{menschifyDate(report.tx_from)}</dd>
                        </dl>
                        {
                            ['super', 'admin'].includes(role) && (
                                <div>
                                    <Link className="no-print btn btn-success mr-2 mb-2" to={`/resource/report/form/${newest_report.entity_uuid}/${newest_report.id}?prefill=${true}`}>
                                        Erstellen
                                    </Link>
                                    <Link className="no-print btn btn-primary mr-2 mb-2" to={`/resource/report/form/${id}/${report.id}`}>
                                        Neu Hinterlegen
                                    </Link>
                                    <Link className="no-print btn btn-danger mb-2" to={`/resource/report/delete/${id}/${report.id}`}>
                                        Löschen
                                    </Link>
                                </div>
                            )
                        }
                    </section>
                    <section className="flex-grow-1 w-50 d-flex flex-column justify-content-between">
                    </section>
                </div>
            </header>
            <main className="container">
                <ReportBody {...data.payload} history={history} role={role} />
            </main>
        </section>
        // <div><pre>{JSON.stringify(data)}</pre></div>
    );
}

export default withRouter(ReportDetail)